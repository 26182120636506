import React, { useState } from 'react';
import Add from "../../images/add-outline.svg";
import Remove from "../../images/remove-outline.svg";

function Accordion  ({ title, description })  {
  const [isExpanded,setIsExpanded] = useState(false);

  const handleClick =()=>{
    setIsExpanded(!isExpanded)
  }

  return (
    <div className='container mx-auto px-7 py-4 '>       
    <div className="grid grid-row gap-10"> 
      <div className="border p-12 py-3 ">
      <div  className="cursor-pointer flex justify-between items-center"  onClick={handleClick}>
        <div className=" font-bold md:text-xl sm:text-sm">{title}</div>
        <div className='text-xl'>{isExpanded ? <Remove/> : <Add/>}</div>
      </div>
      {isExpanded && <div>{description}</div>}
    </div>
    </div>
    </div>
  );
};

export default Accordion;